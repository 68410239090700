import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Try from '../sections/Try/Try'

const AboutPage = () => {
  return (
    <Layout pageName="terms">
      <Seo title="Terms of Service" />
      <Container>
        <section className="data">
          <p className="data__date">Effective June 20, 2019</p>
          <h1 className="heading data__heading">Ally Terms of Service</h1>
          <p>
            he following terms and conditions govern all access to and use of
            the Ally web application, the www.getally.com website (“Website”)
            and service (together, the “Ally Service”) including all content,
            services and products available at or through the Ally Service.
          </p>
          <p>
            The Ally Service is owned and operated by Ally, Inc. (“Ally”, “we”,
            “our” or “us”). The Ally Service is offered subject to your
            acceptance of the terms and conditions contained herein and all
            other operating rules, policies and procedures that may be published
            from time to time on the Website, including the Ally Privacy
            Policy (collectively, the “Agreement”).
          </p>
          <p>
            Please read the Agreement carefully before accessing or using the
            Ally Service. Your accessing or using any part of the Ally Service
            evidences your agreement to be bound by the terms and conditions of
            the Agreement. If you do not agree to all the terms and conditions
            of the Agreement, then you may not access or use the Ally Service.
          </p>

          <h2 className="heading data__heading-sm">1. Ally Service</h2>
          <p>
            The getally.com web application allows you to connect various data
            sources such as Jira, Github or Trello and to aggregate, analyze and
            visualize data from these data sources in Slack, Microsoft Teams,
            Cisco Teams and getally.com website.
          </p>
          <p>
            Ally may also, in the future, update current services and/or
            features or offer new services and/or features to the Ally Service
            (including, the release of new tools and resources). Such updated or
            new features and/or services shall be subject to the terms and
            conditions of the Agreement.
          </p>
          <p>
            Access to the Ally Service is permitted on a temporary basis. We
            reserve the right to modify or discontinue offering or updating the
            Ally Service at any time without notice.
          </p>
          <p>
            After an initial free trial period, if any, your use of the Ally
            Service and your access to the data and information stored in your
            Ally Account requires a paid subscription. The subscription you
            selected will automatically begin and the credit card you provided
            will be charged for that subscription unless you cancel the Ally
            Service before the expiration of a free trial period or the
            preceding subscription period, as the case may be. You can cancel
            your subscription at any time at Website’s Billing Page or by
            contacting customer success team using
            <a href="mailto:support@getally.com">support@getally.com</a>
            email.
          </p>
          <p>
            Until you cancel your subscription, your subscription will be
            renewed automatically and your credit card will be charged for each
            renewal at the end of each subscription period. Prices are subject
            to change upon notice from us. Such notice may be provided at any
            time by posting the changes to the site or the Ally Service itself.
          </p>
          <p>
            If you have any questions, comments or requests regarding your
            subscription, please email
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>

          <h2 className="heading data__heading-sm">2. Slack Service</h2>
          <p>
            Ally and Slack Technologies Inc, the provider of the Slack
            communication service, (“Slack”) are different entities. There is no
            relationship between Ally and Slack, other than Ally being a
            licensee and user of the Slack API for the purpose of providing the
            Ally Service. Slack is not responsible for the Ally Service and will
            not provide support for the Ally Service.
          </p>
          <p>
            These terms do not apply to your use of the Slack services. Such use
            is governed by the Slack Terms of Service available on the website
            www.slack.com.
          </p>

          <h2 className="heading data__heading-sm">
            3. Microsoft Teams Service
          </h2>
          <p>
            Ally and Microsoft Inc, the provider of the Microsoft Teams
            communication service, (“Microsoft Teams”) are different entities.
            There is no relationship between Ally and Microsoft Teams, other
            than Ally being a licensee and user of the Microsoft Teams API for
            the purpose of providing the Ally Service. Microsoft Teams is not
            responsible for the Ally Service and will not provide support for
            the Ally Service.
          </p>
          <p>
            These terms do not apply to your use of the Microsoft Teams
            services. Such use is governed by the Microsoft Teams Terms of
            Service available on the website www.microsoft.com.
          </p>

          <h2 className="heading data__heading-sm">
            4. Microsoft Teams Service
          </h2>
          <p>
            Ally and Atlassian Inc, the provider of the Microsoft Teams
            communication service, (Microsoft Teams) are different entities.
            There is no relationship between Ally and Microsoft Teams, other
            than Ally being a licensee and user of the Microsoft Teams API for
            the purpose of providing the Ally Service. Microsoft Teams is not
            responsible for the Ally Service and will not provide support for
            the Ally Service.
          </p>
          <p>
            These terms do not apply to your use of the Microsoft Teams
            services. Such use is governed by the Microsoft Teans Terms of
            Service available on the website
            https://products.office.com/en-us/microsoft-teams/group-chat-software.
          </p>

          <h2 className="heading data__heading-sm">5. Cisco Teams Service</h2>
          <p>
            Ally and Atlassian Inc, the provider of the Cisco Teams
            communication service, (“Cisco Teams”) are different entities. There
            is no relationship between Ally and Cisco Teams, other than Ally
            being a licensee and user of the Cisco Teams API for the purpose of
            providing the Ally Service. Cisco Teams is not responsible for the
            Ally Service and will not provide support for the Ally Service.
          </p>
          <p>
            These terms do not apply to your use of the Microsoft Teams
            services. Such use is governed by the Cisco Temas Terms of Service
            available on the website
            https://www.webex.com/team-collaboration.html.
          </p>

          <h2 className="heading data__heading-sm">
            6. Use of Ally Service and Creation of Ally Account
          </h2>
          <p>
            In order to use the Ally Service, you must be 18 years of age or
            older, or be 13 years of age or older and have your parent or
            guardian’s consent to the Agreement. You must also have the power to
            enter into a binding contract with us and not be barred from doing
            so under any applicable laws.
          </p>
          <p>
            You may create a Ally Account in your personal capacity (in which
            case the terms “you” or “your” apply to yourself only) or on behalf
            of a company or other legal entity (your “Company”) which you have
            the authority to bind (in which case the terms “you” or “your” shall
            refer to such entity and all permitted Members and Administrative
            Users (as defined in the Slack Terms of Service).
          </p>
          <p>
            By signing into your Slack account on the Website, you grant us
            access to and permission to process Personal Information (as defined
            in the Ally Privacy Policy https://getally.com/privacy.html) and
            other information in your Slack account. Our use, access and
            processing of your Personal information and other information is
            subject to our Privacy Policy https://getally.com/privacy.html which
            is a material part of this Agreement.
          </p>
          <p>
            You are responsible for maintaining the security of your Slack
            Account (including your username and password), and you are fully
            responsible for all activities that occur under your Slack Account
            and any other actions taken in connection with it, whether by
            yourself or other users. You must immediately notify Ally of any
            unauthorized uses and users of your Slack Account integrated with
            the helpdesk or any other breaches of security. Ally will not be
            liable for any acts or omissions by you, including any damages of
            any kind incurred as a result of such acts or omissions.
          </p>

          <h2 className="heading data__heading-sm">7. Intellectual Property</h2>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in the Ally Service and in the material published on it. We grant
            you:
          </p>
          <ul>
            <li>
              a limited, non-exclusive, revocable license to make use of the
              Ally Service (excluding the Website);
            </li>
            <li>
              and a limited, non-exclusive, revocable license to make personal,
              non-commercial use of the Website and the material published on
              it.
            </li>
          </ul>
          <p>
            These licenses shall terminate when the Agreement terminates in
            accordance with Section 9 (Termination).
          </p>
          <p>
            The Agreement does not transfer any of Ally or any of Ally's
            licensors’ intellectual property to you. Title to such intellectual
            property will remain solely with Ally or Ally's licensors (as
            applicable).
          </p>
          <p>
            All Ally trademarks, service marks, trade names, logos, domain
            names, and any other features of the Ally brand are the sole
            property of Ally. Your use of the Ally Service grants you no right
            or license to reproduce or otherwise use any of Ally’s trademarks,
            service marks, trade names, logos, domain names or any other
            features of the Ally brand, whether for commercial or non-commercial
            use.
          </p>

          <h2 className="heading data__heading-sm">8. Third Party Sites</h2>
          <p>
            The Ally Service may contain links to other independent third-party
            websites (“Third-party Sites”). Third-party Sites are not under our
            control, and we are not responsible for and do not endorse their
            content or their privacy policies (if any). You will need to make
            your own independent judgement regarding your interaction with any
            Third-party Sites, including the purchase and use of any products or
            services accessible through them.
          </p>

          <h2 className="heading data__heading-sm">9. Changes</h2>
          <p>
            We reserve the right to change the Agreement at any time for any
            reason. Any changes we may make to the Agreement in the future will
            be notified to you by posting an updated version of the Agreement on
            this page with an updated revision date and, where appropriate, by
            email. Your continued use of or access to the Ally Service following
            the notification of any changes to the Agreement constitutes
            acceptance of those changes.
          </p>

          <h2 className="heading data__heading-sm">10. Termination</h2>
          <p>
            Ally may terminate the Agreement and suspend your access to all or
            any part of the Ally Service immediately by contacting you at your
            email address on record if:
          </p>
          <ul>
            <li>
              you commit a breach of the Agreement, as determined by us in our
              discretion, or we suspect that may be the case;
            </li>
            <li>
              or we consider termination necessary to protect the integrity or
              security of the systems used by us at any time.
            </li>
          </ul>
          <p>
            If you wish to terminate the Agreement, you must cancel your Ally
            Account.
          </p>
          <p>
            On termination of the Agreement by any party and for any reason all
            rights granted to you under the Agreement shall cease and you must
            immediately cease all activities authorized by the Agreement,
            including your use of the Ally Service.
          </p>

          <h2 className="heading data__heading-sm">
            11. Disclaimer of Warranties
          </h2>
          <p>
            To the maximum extent permitted by law, the Ally Service is provided
            “as is” and “as available”. Ally hereby disclaim all warranties of
            any kind, whether express, implied, statutory or otherwise,
            including, without limitation, the warranties of merchantability and
            fitness for a particular purpose and non-infringement. Ally does not
            warrant that the Ally Service will be complete, accurate, reliable,
            timely, secure, error free or that access thereto will be continuous
            or uninterrupted. You understand that you are using the Ally Service
            at your own discretion and risk.
          </p>
          <p>
            We do not warrant, endorse, guarantee or assume responsibility for
            any Messages, or any other product or service advertised or offered
            by a third party on or through the Ally Service.
          </p>
          <p>
            The provisions in this clause do not affect your statutory or
            mandatory rights which cannot be excluded by applicable law.
          </p>

          <h2 className="heading data__heading-sm">
            12. Limitation of Liability
          </h2>
          <p>
            In no event will Ally be liable with respect to any subject matter
            of the Agreement under contract, negligence, strict liability or
            other legal or equitable claim for:
          </p>
          <ul>
            <li>
              (I) any indirect, special, incidental or consequential loss or
              damages;
            </li>
            <li>
              (II) loss of profit, business, revenue, anticipated savings,
              business opportunity;
            </li>
            <li>
              (III) the cost of procurement for substitute products or services;
              or
            </li>
            <li>
              (IV) the cost of interruption of use or loss or corruption of
              data.
            </li>
          </ul>
          <p>
            Ally shall have no liability for any failure or delay due to matters
            beyond its reasonable control. The foregoing shall not apply to the
            extent prohibited by applicable law.
          </p>

          <h2 className="heading data__heading-sm">
            13. Representations and Warranties
          </h2>
          <p>
            You represent and warrant that your use of the Ally Service will be
            in strict accordance with the Agreement. In particular, you
            represent and warrant that:
          </p>
          <ul>
            <li>
              you will comply with the Slack Terms of Service at all times;
            </li>
            <li>
              you will provide us with accurate information (where required);
            </li>
            <li>
              you will not use the Ally Service in the event of an emergency;
            </li>
            <li>
              you will not use the Ally Service in any unlawful manner, for any
              unlawful purpose, or in any manner inconsistent with the
              Agreement, or act fraudulently or maliciously, for example, by
              hacking into or inserting malicious code, including viruses, or
              harmful data, into the Ally Service;
            </li>
            <li>
              you will not infringe our intellectual property rights or those of
              any third party in relation to your use of the Ally Service
            </li>
            <li>
              you will not do any of the following in relation to your use of
              the Ally Service:
            </li>
            <li>be obscene, offensive, hateful or inflammatory,</li>
            <li>defame any person,</li>
            <li>
              promote discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age,
            </li>
            <li>promote violence,</li>
            <li>promote any illegal activity,</li>
            <li>promote sexually explicit material,</li>
            <li>
              disclose the name, address, telephone number, e-mail address or
              any other personal data in respect of any individual without their
              consent,
            </li>
            <li>
              harass, upset, embarrass, threaten, alarm or annoy any other
              person,
            </li>
            <li>be likely to mislead or deceive any person,</li>
            <li>
              impersonate any person, or misrepresent your identity or
              affiliation with any person,
            </li>
            <li>
              contain any advertising or promote any services or web links to
              other sites;
            </li>
            <li>
              you will not use the Ally Service in a way that could damage,
              disable, overburden, impair or compromise our systems or security
              or interfere with other users; and
            </li>
            <li>
              you will not collect or harvest any information or data from any
              content or our systems or attempt to decipher any transmissions to
              or from the servers running the Ally Service.
            </li>
          </ul>

          <h4 className="heading data__heading-sm">14. Indemnification</h4>
          <p>
            You agree to indemnify and hold harmless Ally and its respective
            directors, officers, employees and agents from and against any and
            all claims and expenses, including attorneys’ fees, arising out of
            your use of the Ally Service, including but not limited to your
            violation of the Agreement.
          </p>

          <h4 className="heading data__heading-sm">15. Miscellaneous</h4>
          <p>
            The Agreement constitutes the entire agreement between Ally and you
            concerning the provision of the Ally Services.
          </p>
          <p>
            The Agreement is between you and us and is not intended to grant
            rights, including the right to enforce any of its terms, to any
            other person. Any failure or delay by us to enforce the Agreement or
            any provision thereof shall not waive our right to do so.
          </p>
          <p>
            We may transfer our rights and obligations under the Agreement to a
            third party, but this will not affect your rights or obligations
            under the Agreement.
          </p>
          <p>
            Each of the terms and conditions of the Agreement operate
            separately. If any court or competent authority decides that any of
            them are unlawful or unenforceable, the remaining conditions will
            remain in full force and effect and will be construed as far as
            possible to give effect to the parties’ intentions as originally
            expressed in the Agreement.
          </p>

          <h4 className="heading data__heading-sm">16. Contact</h4>
          <p>
            If you have any questions, comments or requests regarding the
            Agreement, please email
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>
        </section>
        <Try />
      </Container>
    </Layout>
  )
}

export default AboutPage
